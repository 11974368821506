@media only screen and (max-width: 425px){
	.App{
		flex-wrap: wrap;
	}

	.content{
		width: 100%;
	}

	.image img{
		width: 100vw;
	}
}


@media only screen and (min-width: 768px){
	.App{
		width: 100%;
	}
  .content{
		flex-basis: 70%;
	}
}
