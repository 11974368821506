@import url(https://fonts.googleapis.com/css?family=Josefin+Sans:700|Roboto+Mono:700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media only screen and (max-width: 425px){
	.App{
		flex-wrap: wrap;
	}

	.content{
		width: 100%;
	}

	.image img{
		width: 100vw;
	}
}


@media only screen and (min-width: 768px){
	.App{
		width: 100%;
	}
  .content{
		flex-basis: 70%;
	}
}

.josefina{
	font-family: 'Josefin Sans', sans-serif;
}

.robo-mono{
	font-family: 'Roboto Mono', monospace;
	font-weight: 700;
	font-size: 80px;
	margin: unset;
}

/* common classes */
.flex-col{
	display: flex;
	flex-direction: column;
}

.flex-row{
	display: flex;
	flex-direction: row;
}

.flex-col-centered{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}


.flex-row-centered{
	display: flex;
	flex-direction: row;
	align-items: center;
}

/* app styles */

.App {
	text-align: center;
}


.image{
	flex-basis: 30%;
}

.image img{
	/* width: 10vw; */
	height: 100vh;
}


.header{
	justify-content:space-between;
	height: 7vh;
	padding: 3px;
}

.signature{
	height: 30px;
	margin-top: 5px;
	margin-right: 5px;
	margin-left: 10px;
}

.brand{
	width: 60px;
	height: 40px;
	margin-top: 5px;
}

.body{
	height: 92vh;
}

#first{
	font-weight: 700;
	font-size: 20px;
	margin: unset;
}

.social-media{
	width: 16px;
	margin-right: 7px;
	cursor: pointer;
	opacity: 1;
	-webkit-transition: opacity 2s ease-in-out;
	transition: opacity 2s ease-in-out;
}

.social-media:hover{
	opacity: 0;
}
