@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:700|Roboto+Mono:700&display=swap');

.josefina{
	font-family: 'Josefin Sans', sans-serif;
}

.robo-mono{
	font-family: 'Roboto Mono', monospace;
	font-weight: 700;
	font-size: 80px;
	margin: unset;
}

/* common classes */
.flex-col{
	display: flex;
	flex-direction: column;
}

.flex-row{
	display: flex;
	flex-direction: row;
}

.flex-col-centered{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}


.flex-row-centered{
	display: flex;
	flex-direction: row;
	align-items: center;
}

/* app styles */

.App {
	text-align: center;
}


.image{
	flex-basis: 30%;
}

.image img{
	/* width: 10vw; */
	height: 100vh;
}


.header{
	justify-content:space-between;
	height: 7vh;
	padding: 3px;
}

.signature{
	height: 30px;
	margin-top: 5px;
	margin-right: 5px;
	margin-left: 10px;
}

.brand{
	width: 60px;
	height: 40px;
	margin-top: 5px;
}

.body{
	height: 92vh;
}

#first{
	font-weight: 700;
	font-size: 20px;
	margin: unset;
}

.social-media{
	width: 16px;
	margin-right: 7px;
	cursor: pointer;
	opacity: 1;
	transition: opacity 2s ease-in-out;
}

.social-media:hover{
	opacity: 0;
}